:root {
    font-size: 0.6rem; /* for example */
}

body {
    background-color: lightgray;
}


.bg-login-image {
    background: url("/public/assets/img/login-waf.jfif");
    background-position: center;
    background-size: cover;
}

/*
# Est utilisé pour les boutons "btn-secondary btn-icon-split"
*/
.rental-full-with {
  width: 100%;
  justify-content: left;
}

.btn-secondary {
    color: #fff;
    background-color: #858796;
    border-color: #858796;
}

.control-label {
    text-align: right;
    vertical-align: middle;
}

.TXTInfo {
    color: #424242;    
}


.select2-choices {
  min-height: 150px;
  max-height: 150px;
  overflow-y: auto;
}

/*Pour faire en sorte de repérer la version BETA*/
.bg-gradient-primary-to-secondary-beta {
    background-color: #e81500;
    background-image: linear-gradient(135deg, #eec7c7 0%, #e81500 100%);
}

/*Pour faire en sorte de repérer la version CR*/
.bg-gradient-primary-to-secondary-cr {
    background-color: #00ac69;
    background-image: linear-gradient(135deg,#00ac69,#0c5d0c);
}

/*
    
    A certain moment, on souhaite ne pas avoir une liste IMMENNNNNSE
    Il faut préciser à l'instanciation de SELECT2 : containerCssClass: "my-select2-container-class",
 
    $(".select2-my-special-select").select2({
        containerCssClass: "my-select2-container-class",
        dropdownCssClass: "test"
    });
*/

.select2-limitedHeight {
    height: 60px;
    overflow-y: scroll;
} 

/* pour la hauteur des zones de DOWNLOAD */
.ajax-upload-dragdrop {
/*    height: 100px;*/
}

/* pour que notre menu soit jolis !*/
.topnav .navbar-brand {
    width: 15rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0;
    font-size: 1rem;
    font-weight: normal !important;
}

/*
  X----------------------------------------------------------------------
  | Tuning des Datatables !
  X----------------------------------------------------------------------     
 */


/* 
    Est utilisé pour le scanner : on augmente la taille pour que ce soit visible sur GSM 
*/
.TextBigSize {
    font-size: 4em;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    box-sizing: border-box;
    display: inline-block;
    min-width: 1.5em;
    padding: 6px;
    margin-left: 0px;
    text-align: center;
    text-decoration: none !important;
    cursor: pointer;
    *cursor: hand;
    color: #333333 !important;
    border: none;
    border-radius: 2px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
    cursor: default;
    color: #666 !important;
    border: 0px;
    background: transparent;
    box-shadow: none;
}

.dtrg-start,
.dtrg-start:hover {
    background-color: #0489B1 !important;
    color: #FFF;
}

.dtrg-end,
.dtrg-end:hover {
    background-color: #CEE3F6 !important;
    color: #6E6E6E;
}

#dtBtCHOIX  {     background-color: #22B06A; }
#dtBtOPT    {     background-color: #f6c23e; }
#dtBtSAVE   {     background-color: #1cc88a; }
#dtBtRefresh {  background-color: #FF8000; }
#dtBtDEL    {      background-color: red;  }

/*La couleur des choix d'ITEMS, AUTOCOMPLETE*/
.articleStockMoyen { background-color: #FFBC57 }
.articleStockVide  { background-color: #FFCDD2 }


/*Pour quand une ligne est sélectionnée dans les Datatables, elle soit en Blanc ! (sur fond bleu) */
table.dataTable > tbody > tr.selected > td,
table.dataTable > tbody > tr.selected > th
{
    color: white !important;
    background-color: #DBDBDB !important;
}

/*Pour quand une ligne est sélectionnée dans les Datatables, son INPUT BOX change aussi de couleur*/
table.dataTable > tbody > tr.selected > td > input
{
    color: white !important;
}

/*Pour quand une ligne est sélectionnée dans les Datatables, son TEXTAREA change aussi de couleur*/
table.dataTable > tbody > tr.selected > td > textarea
{
    color: white !important;
}

.form-control-dt {
    display: block;
    width: 100%;
    height: calc(1.5em);
/*    height: calc(1.5em + 1rem + 2px);*/
/*    padding: 0.5rem 1rem;*/
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #687281;
    background-color: rgba(255, 255, 255, 0);
    background-clip: padding-box;
    border: 0px;
    border-bottom: 1px solid #D8D8D8;
    /*border-radius: 0.35rem;*/
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control-dt-group {
/*    display: block;*/
    width: 50%;
    height: calc(1.5em);
/*    height: calc(1.5em + 1rem + 2px);*/
/*    padding: 0.5rem 1rem;*/
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #FFF;
    background-color: rgba(255, 255, 255, 0);
    background-clip: padding-box;
    border: 0px;
/*    border-bottom: 1px solid #D8D8D8;*/
    /*border-radius: 0.35rem;*/
    -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-weight: bold;
}

.form-control-dt.trackInputDesc {
    margin-top: 0px;
    margin-bottom: 0px;
    min-height: 29px;

/*    Depuis l'utilisation de AUTOSIZE, plus besoin de pousser une val max*/
/*    max-height: 150px;*/
}

/*Est utilisé pour le bloc des OPERATIONS a faires sur les PO, dans la page des commandes*/
.RENTAL-stickyHeader {
    position: fixed;
    width: 100%;
    top: 75px;    
}

/* est utilisé pour les BON PREPAS : défini un cadre autour de la zone d'ajout */
.RENTAL-Sticky-TOP {
    width: 100%;
    height: 60px;
    background-color: #FFF;
    border-top:  2px solid #A4A4A4;
    border-bottom:  2px solid #A4A4A4;
    border-left:  2px solid #A4A4A4;
    border-right:  2px solid #A4A4A4;

    border-radius: 5px;
    margin-bottom: 2rem;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
}

/* est utilisé pour les ARTICLES (ou tout ce qui est dans une page classique, et qui passe SOUS la bare du haut */
.RENTAL-Sticky-TOP-MENU {
    width: 100%;
    height: 60px;
    background-color: #FFF;
    border-top:  2px solid #A4A4A4;
    border-bottom:  2px solid #A4A4A4;
    border-left:  2px solid #A4A4A4;
    border-right:  2px solid #A4A4A4;

    border-radius: 5px;
    margin-bottom: 2rem;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-right: 0.2rem;
    margin-left: 0.2rem;

    /* pour le placement du MENU */
    padding-top: 4rem;

}

/* Est utilisé pour placer nos toast sur le dessus !*/
.alert-fixed {
    position:fixed; 
    z-index:9999; 
}

/*Est utilisé pour placer le bandeau des ITEMS, en bas de la page, en fixe*/
#fixedBottom {
    position: fixed;
    bottom: 0px;
    margin: 0px;
    width: 100%;
/*    height: 95px;*/
    background-color: #FFF;
    border-top:  2px solid #A4A4A4;
    border-left:  2px solid #A4A4A4;
    border-right:  2px solid #A4A4A4;
    border-top-left-radius: 5px;    
    border-top-right-radius: 5px;    
/*    z-index: 30;*/
}

/*Est utilisé pour placer le bandeau des PO, en bas de la page, en fixe*/
#fixedBottomPO {
    position: fixed;
    bottom: 0px;
    margin: 0px;
    width: 100%;
/*    height: 95px;*/
    background-color: #FFF;
    border-top:  2px solid #A4A4A4;
    border-left:  2px solid #A4A4A4;
    border-right:  2px solid #A4A4A4;
    border-top-left-radius: 5px;    
    border-top-right-radius: 5px;    
/*    z-index: 30;*/
}

/*#fixedBottom {
    position: fixed;
    bottom: 0px;
    margin: 0px;
    width: 100%;
    height: 60px;
    background-color: #FFF;
    border-top:  2px solid #A4A4A4;
    border-left:  2px solid #A4A4A4;
    border-right:  2px solid #A4A4A4;
    border-top-left-radius: 5px;    
    border-top-right-radius: 5px;    
}
*/

.fixedCenterPosition {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 98%;
}

.fixedCenterPositionL2 {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 98%;
}


.fixedTopPositionLoaderLogin {
    position: fixed;
    top: 0px;
    z-index: 9999;
    width: 100%;    
}
/* 
  X----------------------------------------------------------------------
  | Tuning des FANCYTREE !
  X----------------------------------------------------------------------     
 */
/* Police à CHASSE FIXE */
@font-face{ 
    font-family: 'RentalFixe';
    src: url('/public/assets/css/font/AurulentSansMono-Regular.otf');
}

table.fancytree-ext-table {
    font-size: 12pt;
    font-family: Nunito,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}
table.fancytree-ext-table tbody tr.fancytree-folder span.fancytree-expander {
  background-image: url("/public/assets/img/plus.gif");
}
table.fancytree-ext-table tbody tr.fancytree-folder.fancytree-expanded span.fancytree-expander {
    background-image: url("/public/assets/img/minus.gif");
}
table.fancytree-ext-table tbody tr td {
    border: none;   
}
table.fancytree-ext-table thead tr th {
    border-bottom: 1px solid #111;
    padding: 10px 18px;
}
span.fancytree-title.Padding40 {
    padding-left: 41px;
}

span.fancytree-title {
    color: #000;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    min-height: 1em;
    /* padding: 0 3px 0 3px; */
    margin: 0 0 0 .5em;
    border: 1px solid transparent;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
}
/* 
  X----------------------------------------------------------------------
  | Tuning des UPLOADS !
  X----------------------------------------------------------------------     
 */

.ajax-upload-dragdrop {
    border: 1px solid;
    border-radius: 0.35rem;
}

/* 
  X----------------------------------------------------------------------
  | Tuning de sb-admin !
  X----------------------------------------------------------------------     
 */
.page-header .page-header-content {
  padding-top: 1rem;    /* moin d'espace au dessus */
  padding-bottom: 1rem; /* moin d'espace au dessou */
}

.nav-fixed #layoutSidenav #layoutSidenav_content {
    padding-left: 12rem;
/*    top: 3.625rem;*/
top: 2rem;

}

.nav-fixed #layoutSidenav #layoutSidenav_nav {
    width: 12rem; 
    height: 100vh;
    z-index: 1038;
}

/* Icone devant les boutons */

.btn-icon-split {
    padding: 0;
}

.btn-icon-split .icon {
    background: rgba(0,0,0,.15);
    display: inline-block;
    padding: .375rem .75rem;
}

.btn-icon-split .text {
    display: inline-block;
    padding: .375rem .75rem;
}

.text-white-50 {
    color: rgba(255,255,255,.5)!important;
}

.btn-primary {
    color: #fff;
    background-color: #4e73df;
    border-color: #4e73df;
}

input[type=radio], input[type=checkbox] {

    width: 18px;
    height: 18px;
}

/* 
  X----------------------------------------------------------------------
  |  Est utilisé pour les Autocomplete COMPLEXE !
  X----------------------------------------------------------------------     
 */


/* Obligatoire quand on est dans un MODAL */
.ui-autocomplete {
    z-index: 215000000 !important;

    /* On aime ajouter un SCROLL quand la liste est trop grande*/
    max-height: 600px;

    overflow-y: auto; 
    /* prevent horizontal scrollbar */
    overflow-x: hidden;
    /* add padding to account for vertical scrollbar */
/*    padding-right: 20px;  */
}

.ui-menu-item .ui-menu-item-wrapper.ui-state-active {
    background: #bdbdbd !important;
    font-weight: bold !important;
    color: #ffffff !important;
    border: 0px !important;
    padding: 3px 0px 0px 0px !important;
} 

/*Fine tuning de la largeur et hauteur de la DROPDOWN du select2*/
.select2-container--default .s2-mh-10 {
    width: 912px !important;
}

.select2-container--default .s2-mh-10 .select2-results>.select2-results__options {
    max-height: 580px !important;
    width: 900px;
}

.select2-container--default .s2-mh-10 .select2-results>.select2-results__options>.select2-results__option {
    min-height: 58px !important;

}

/*Fine tuning de la largeur et hauteur de la DROPDOWN du select2*/
.select2-container--default .s2-mh-20 {
    width: 1102px !important;
}

.select2-container--default .s2-mh-20 .select2-results>.select2-results__options {
    max-height: 580px !important;
    width: 1100px;
}

.select2-container--default .s2-mh-20 .select2-results>.select2-results__options>.select2-results__option {
    min-height: 58px !important;

}

.select2-items-IMG {
    width: 60px; 
    float: left;
}

.select2-items-REF {
    color: #333;    
    float: left;
    text-align: left;
    width: 120px;  
    font-size: 12px;
    padding: 3px 0px 3px 0px;    
}

.select2-items-TYPE {
    color: #333;    
    float: left;
    text-align: left;
    width: 90px;  
    font-size: 12px;
    padding: 3px 0px 3px 0px;    
}

.select2-items-NAME {
    color: #333;    
    float: left;
    text-align: left;
    font-size: 12px;
    width: 610px;

    /*    padding: 3px 0px 3px 0px;    Fait foirer quand l'écriture est trop grande / longue */
    /* Si le texte est trop grand, et vu que nous somme en float, alors le texte de la lighe suivante est décallé
        Ici, placons une valeur MAX et un petit scroller **si besoin** */
    max-height: 55px;
    overflow-y: auto;
}

.select2-items-PA {
    color: #333;    
    float: left;
    text-align: left;
    width: 90px;  
    font-size: 12px;
    padding: 3px 0px 3px 0px;    
}

.select2-items-QT {
    color: #333;    
/*    float: left;*/
    text-align: left;
    width: 90px;  
    font-size: 12px;
    padding: 3px 0px 3px 0px;    
}

/* Est utilisé pour l'autocomplete des Catégorie */
.ui-menu-item-wrapper.ui-state-active .ART_MOD_REF {    background: #bdbdbd !important; }
.ui-menu-item-wrapper .ART_MOD_REF {
    color: #333;    
    float: left;
    text-align: left;
    width: 120px;  
    font-size: 12px;
    padding: 3px 0px 3px 0px;
}

.ui-menu-item-wrapper.ui-state-active .ART_MOD_VALUE {    background: #bdbdbd !important; }
.ui-menu-item-wrapper .ART_MOD_VALUE {
    color: #333;    
    float: left;
    text-align: left;
    width: 210px;   
    font-size: 12px;
    padding: 3px 0px 3px 0px;
}

/* Est utilisé pour l'autocomplete des ARTICLES */
.ui-menu-item-wrapper.ui-state-active .ART_DET_REF {    background: #bdbdbd !important; }
.ui-menu-item-wrapper .ART_DET_REF {
    color: #333;    
    float: left;
    text-align: left;
    width: 160px;    
    font-size: 12px;
    padding: 3px 0px 3px 0px;
}

.ui-menu-item-wrapper.ui-state-active .ART_DET_TYPE {    background: #bdbdbd !important; }
.ui-menu-item-wrapper .ART_DET_TYPE {
    color: #333;    
    float: left;
    text-align: left;
    width: 70px;    
    font-size: 12px;
    padding: 3px 0px 3px 0px;
}

.ui-menu-item-wrapper.ui-state-active .ART_DET_ITEM {    background: #bdbdbd !important; }
.ui-menu-item-wrapper .ART_DET_ITEM {
    color: #333;    
    float: left;
    text-align: left;
    width: 750px;    
    font-size: 12px;
    padding: 3px 0px 3px 0px;
}

.ui-menu-item-wrapper.ui-state-active .ART_DET_PA {    background: #bdbdbd !important; }
.ui-menu-item-wrapper .ART_DET_PA {
    color: #333;    
    float: left;
    text-align: left;
    width: 130px;    
    font-size: 12px;
    padding: 3px 0px 3px 0px;
}

.ui-menu-item-wrapper.ui-state-active .ART_DET_QT {    background: #bdbdbd !important; }
.ui-menu-item-wrapper .ART_DET_QT{
    color: #333;    
    float: left;
    text-align: left;
    width: 90px;   
    font-size: 12px; 
    padding: 3px 0px 3px 0px;
}

.ui-menu-item-wrapper.ui-state-active .ART_EMPTY {    background: #bdbdbd !important; }
.ui-menu-item-wrapper .ART_BASE{
    margin-bottom: 5px;   
    background: #FFF;
}

/* 
  X----------------------------------------------------------------------
  |  Est utilisé pour les AGENDAS !
  X----------------------------------------------------------------------     
 */

.dhtmlx_modal_box,
.dhtmlx-alert,
.dhx_cal_light_wide,
.dhx_cal_light   { 
    z-index: 99999; 
}


/*On veut que nos textes soient petit dans les blocs des agendas*/
.dhx_cal_event .dhx_body, 
.dhx_cal_event.dhx_cal_select_menu .dhx_body {
    line-height: normal !important;
    font-size: smaller;
}

/* 
  X----------------------------------------------------------------------
  |  Est utilisé pour les ARTICLE USE !
  X----------------------------------------------------------------------     
 */
.vis-item.green {
    background-color : greenyellow;
    border-color: green;
}

.vis-item.red {
    background-color : red;
    border-color: darkred;
}

.vis-item .vis-item-overflow {
    overflow: visible;
}

/* 
  X----------------------------------------------------------------------
  |  Est utilisé pour placer les label de checkbox à gauche au lieu de droite !
  X----------------------------------------------------------------------     
 */
.form-checkbox-left {
    float: left;
    padding-left: 2.5rem !important;
}

.form-checkbox-left .form-check-input {
    margin-left: -2.5rem !important;
}

.form-checkbox-left .form-check-label {
    padding-top: 0.5rem !important;
}

/*Une box qui reste fixe, et qui se déplace à la souris ?*/
.RENTAL-Mouse-DragAndMove {
    position: fixed;
/*    width: 900px;*/
/*    top: 60rem;*/
    left: 60rem;
/*    right: 10rem;*/
    height: 200px;
    bottom: 10rem;
    z-index: 200;    
}

.articlesTree_thumbnail {
    margin-left: 45px;
    margin-right: 15px;
    float: left;
}

@keyframes clignote {  
  50% { opacity: 0; }
}